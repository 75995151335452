import * as React from 'react';
import IndexLayout from '../layouts';
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';
import '../components/Papers/styles.scss';
import axios from 'axios';


// images
import HeaderEs from '../components/Papers/header_es.png';
import HeaderEn from '../components/Papers/header_en.png';
import Book from '../components/Papers/book.png';
import { Download } from '@kushki/suka-react-system-icons';
import backgroundPapers from '../components/Papers/background-papers.jpg';
import placeholder from '../components/Papers/placeholder.jpg';
import backgroundBlog from '../components/Papers/background-blog.jpg';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';


interface PapersProps {
    pathContext: {
        localeCode: LocaleTypes;
    };
    location: Location;
}

interface CustomWindow extends Window {
    dataLayer: any[]; // O el tipo específico que estés utilizando en tu dataLayer
}

declare let window: CustomWindow;


const PaperTemplate: React.SFC<PapersProps> = ({ pathContext, location }) => {
    const { localeCode } = pathContext;
    const { allContentfulEbook, allContentfulBlogPost } = useStaticQuery(
        graphql`
        {
            allContentfulEbook {
              edges {
                node {
                    title
                    slug
                    node_locale
                    subtitle
                    ebookPdf {
                        file {
                        url
                        }
                    }
                    ktalks
                    ebookImage {
                        file {
                            url
                        }
                    }
                }
              }
            }
            allContentfulBlogPost(limit: 3) {
                edges {
                    node {
                    title
                    slug
                    body {
                        childMarkdownRemark {
                        excerpt
                        }
                    }
                    mainImage {
                        localFile {
                        publicURL
                        }
                    }
                    }
                }
            }
          }
        `,
    );

    const [loading, setLoading] = React.useState(true);
    const [blogs , setBlogs] = React.useState([]);
    const [ebooks, setEbooks] = React.useState([]);
    const [mainEbook, setMainEbook] = React.useState<any>([]);

    console.log('allContentfulEbook', allContentfulEbook);
    console.log('allContentfulBlogPost', allContentfulBlogPost);

    React.useEffect(() => {
        if (allContentfulEbook && allContentfulBlogPost) {
            setLoading(false);
            setBlogs(allContentfulBlogPost.edges);
            //filter by node_locale
            let allContentfulEbookList: any = allContentfulEbook.edges;
            let allContentfulEbookListMain = allContentfulEbookList.filter((item: any) => item.node.slug === "tendencias-de-pago-2024-en-america-latina-la-tecnologia-marca-el-futuro");
            allContentfulEbookList = allContentfulEbookList.filter((item: any) => item.node.slug !== "tendencias-de-pago-2024-en-america-latina-la-tecnologia-marca-el-futuro");
            allContentfulEbookList = allContentfulEbookList.filter((item: any) => item.node.slug !== "pymnts");
            allContentfulEbookList = allContentfulEbookList.filter((item: any) => item.node.slug !== "pymnts-2");
            allContentfulEbookList = allContentfulEbookList.filter((item: any) => item.node.slug !== "perspectiva-fintech");
            allContentfulEbookList = allContentfulEbookList.filter((item: any) => item.node.slug !== "gat-2023");
            allContentfulEbookList = allContentfulEbookList.filter((item: any) => item.node.slug !== "tendencias-pagos-digitales");
            allContentfulEbookList = allContentfulEbookList.filter((item: any) => item.node.node_locale === localeCode);
            allContentfulEbookListMain = allContentfulEbookListMain.filter((item: any) => item.node.node_locale === "es");
            setEbooks(allContentfulEbookList);
            if(allContentfulEbookListMain.length > 0) setMainEbook(allContentfulEbookListMain);
            console.log('allContentfulEbookListMain', allContentfulEbookListMain);
        }
    }, [allContentfulEbook, allContentfulBlogPost]);

    const [form, setForm] = React.useState({
        name: '',
        lastName: '',
        email: '',
        country: "0",
        company: '',
        industry: ''
    });
    const [errorForm, setErrorForm] = React.useState("");
    const [loadingForm, setLoadingForm] = React.useState(false);
    const handleForm = (e: any) => {
        setErrorForm('');
        e.preventDefault();
        //validate all fields
        console.log('form', form);
        if(form.name === '' || form.lastName === '' || form.email === '' || form.country === "0" || form.company === '' || form.industry === ''){
            setErrorForm(localeCode === 'es' ? 'Por favor complete todos los campos' : 'Please complete all fields');
            return;
        }
        setLoadingForm(true);
        //send data
        axios.post('https://hooks.zapier.com/hooks/catch/1396001/3es78zr/', form, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response: any) => {
            console.log('response', response);
            setLoadingForm(false);
            setErrorForm(localeCode === 'es' ? 'Gracias por descargar el paper' : 'Thanks for downloading the paper');
            setForm({
                name: '',
                lastName: '',
                email: '',
                country: "0",
                company: '',
                industry: ''
            });
            //download paper
            const filePath = mainEbook.length > 0 ? mainEbook[0].node.ebookPdf.file.url : 'https://downloads.ctfassets.net/51xdmtqw3t2p/4BxQNKX9CFsYvqgAOHVqy/a2ee16eb478296f18e67789085db0199/Ebook_-_Tendencias_de_pagos_en_2024__2_.pdf';
            const pdfWindow = window.open();
            if (pdfWindow) {
                pdfWindow.location.href = filePath;
            }
        }).catch((error: any) => {
            console.log('error', error);
            setLoadingForm(false);
            setErrorForm('Ocurrió un error, por favor intente de nuevo');
        });
    }



    const content = () => (
        <>
            {/* <SEO titleId="Papers y webinars" descriptionId="Papers y webinars" /> */}

            <section className="container-fluid">

                {/* header */}
                <div className="container formContainer">
                    <div className="row justify-content-center align-items-start">
                        <div className="col-xs-12 col-md-6 text-left" style={{paddingTop: "15vh"}}>
                            <h2 className="my-title" style={{ color: "#1E65AE" }}>
                                <FormattedMessage id="papers.header.subtitle" />
                            </h2>
                            <h1 className="my-subtitle" style={{ color: "#1E65AE" }}><b style={{fontWeight: "bold"}}>
                                <FormattedMessage id="papers.header.title" />
                            </b></h1>
                            <p className="my-body" style={{ color: "#293036" }}>
                                <FormattedMessage id="papers.header.description" />
                            </p>
                        </div>
                        <div className="col-xs-12 col-md-6 text-center">
                            <img src={
                                localeCode === 'es' ? HeaderEs : HeaderEn
                            } alt="cover" className="my-image img-fluid" />
                        </div>
                    </div>
                </div>

                {/*  form */}
                <div className="container-fluid" style={{ textAlign: "center", backgroundColor: "#E2E8F0", padding: "10vh 20pt" }}>
                    <div className="container" style={{ maxWidth: "lg" }}>
                        <h2 className="my-title" style={{ color: "#677684", marginBottom: "40pt", fontSize: "24px" }}>
                            <FormattedMessage id="papers.form.title" />
                        </h2>
                        <form style={{ width: "85%", margin: "0 auto" }} onSubmit={handleForm}>
                            {/* add a form with card white background */}
                            <div className="card card-form" style={{ boxShadow: "0 0 10pt 0 rgba(0,0,0,0.1)", width: "100%" }}>
                                <div className="card-body">
                                    {/* add label and input in row */}
                                    <div className="row justify-content-between align-items-center mb-2">
                                        <div className="col-xs-12 col-md-6 px-2">
                                            <input className="form-control input-form" type="text" placeholder={ localeCode === 'es' ? 'Nombre' : 'Name'} onChange={(e) => setForm({...form, name: e.target.value})} value={form.name} />
                                        </div>
                                        <div className="col-xs-12 col-md-6 px-2">
                                            <input className="form-control input-form" type="text" placeholder={ localeCode === 'es' ? 'Apellido' : 'Last Name'} onChange={(e) => setForm({...form, lastName: e.target.value})} value={form.lastName} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-between align-items-center mb-2">
                                        <div className="col-xs-12 col-md-6 px-2">
                                            <input className="form-control input-form" type="text" placeholder={ localeCode === 'es' ? 'Correo Electrónico' : 'Email'} onChange={(e) => setForm({...form, email: e.target.value})} value={form.email} />
                                        </div>
                                        <div className="col-xs-12 col-md-6 px-2">
                                            <select className="form-select input-form" onChange={(e) => setForm({...form, country: e.target.value})} value={form.country}>
                                                <option value="0">{
                                                    localeCode === 'es' ? 'País' : 'Country'
                                                }</option>
                                                <option value="Mexico">México</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Peru">Perú</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Chile">Chile</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between align-items-center mb-2">
                                        <div className="col-xs-12 col-md-6 px-2">
                                            <input className="form-control input-form" type="text" placeholder={ localeCode === 'es' ? 'Empresa' : 'Company'} onChange={(e) => setForm({...form, company: e.target.value})} value={form.company} />
                                        </div>
                                        <div className="col-xs-12 col-md-6 px-2">
                                            <input className="form-control input-form" type="text" placeholder={ localeCode === 'es' ? 'Industria' : 'Industry'} onChange={(e) => setForm({...form, industry: e.target.value})} value={form.industry} />
                                        </div>
                                    </div>

                                    <div className="row justify-content-between align-items-center mb-2">
                                        {
                                            errorForm !== '' && <div className="alert alert-warning" role="alert" style={{ width: "100%" }}>
                                                {errorForm}
                                            </div>
                                        }
                                    </div>

                                    <div className="row justify-content-between align-items-center mb-2 px-2">
                                        <button className="btn--green" type="submit" style={{ background: "#00FCC1"}}>
                                            <Download size={20} />
                                            <FormattedMessage id="papers.form.btn" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/*  estudios */}
                <div className="container-fluid" style={{ textAlign: "center", backgroundColor: "#F0F4F8", padding: "10vh 20pt" }}>
                <div className="container" style={{ maxWidth: "lg"}}>
                    <div className="row justify-content-center">
                        <div className="col-lg-12 formContainer">
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <div className="text-left mb-2" style={{ width: "100%", maxWidth: "50%" }}>
                                    <div className="d-flex align-items-center mb-2" style={{ gap: "10pt" }}>
                                        <div className="line-text"></div>
                                        <h3 className="text-primary">
                                            <FormattedMessage id="papers.list.title" />
                                        </h3>
                                    </div>
                                    <p className="mb-0">
                                        <FormattedMessage id="papers.list.description" />
                                    </p>
                                </div>
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-md-6 mb-3">
                                        <img src={Book} alt="cover" className="img-fluid" />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <ul className="list-unstyled list-ul">
                                            <li><FormattedMessage id="papers.list.items.item1" /></li>
                                            <li><FormattedMessage id="papers.list.items.item2" /></li>
                                            <li><FormattedMessage id="papers.list.items.item3" /></li>
                                            <li><FormattedMessage id="papers.list.items.item4" /></li>
                                            <li><FormattedMessage id="papers.list.items.item5" /></li>
                                            <li><FormattedMessage id="papers.list.items.item6" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                {/* Download */}
                <div className="container-fluid background-cover" style={{ textAlign: "center", background: "url(" + backgroundPapers + ") no-repeat", backgroundSize: "cover", padding: "10vh 20pt" }}>
                    <div className="container">
                        <h2 className="text-white mb-2">
                            <FormattedMessage id="papers.download.title" />
                        </h2>
                        <p className="text-white" style={{ marginBottom: "40pt" }}>
                            <FormattedMessage id="papers.download.description" />
                        </p>
                        <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ width: "100%", gap: "20pt" }}>
                            {
                               loading && [1, 2, 3, 4, 5].map((item, index) => (
                                    <div className="card-list text-white" key={index}>
                                        <img src={placeholder} className="card-img-top" alt="cover" />
                                        <div className="card-body">
                                            <p className="card-text"><span className="placeholder col-12">
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                            </span>:</p>
                                            <h4 className="card-title mb-2 text-white">
                                            <span className="placeholder col-12">
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                                            </span>
                                            </h4>
                                            <button className="btn--green" type="submit" style={{ margin: "0 auto" }}>
                                                <Download size={20} />
                                                <FormattedMessage id="papers.form.btn" />
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                            { !loading && ebooks.map((item: any, index: any) => (
                                <div className="card-list text-white" key={index}>
                                    <img src={item.node.ebookImage.file.url} className="card-img-top" alt="cover" />
                                    <div className="card-body d-flex flex-column" style={{ height: "200pt" }} >
                                        {/* <p className="card-text">{item.node.subtitle}</p> */}
                                        <h4 className="card-title mb-2 text-white" style={{ flex: "1" }}>
                                            {item.node.title}
                                        </h4>
                                        <a href={item.node.ebookPdf.file.url} className="btn--green" type="submit" style={{ margin: "0 auto", cursor: "pointer", background:"#00FCC1" }} download target='_blank'>
                                            <Download size={20} />
                                            &nbsp;&nbsp;
                                            <FormattedMessage id="papers.form.btn" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/*  webinars */}
                <div className="container" style={{ maxWidth: "lg", padding: "10vh 20pt" }}>
                    <div className="container">
                        <h2 className="text-primary text-center" style={{ marginBottom: "40pt" }}>Webinars</h2>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-12 mb-4">
                                <iframe className="embed-responsive-item video-frame" src="https://www.youtube.com/embed/E9FP7zLVzpY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                <p className="text-primary mb-2" style={{ fontSize: "24px", fontWeight: "600" }}>
                                <FormattedMessage id="papers.webinars.video1" />
                                </p>
                            </div>
                            <div className="col-md-6 mb-4 px-2">
                                <iframe className="embed-responsive-item video-frame video-frame-mini" src="https://www.youtube.com/embed/lFM44Mdi2CA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                <p className="text-primary mb-2" style={{ fontSize: "24px", fontWeight: "600" }}>
                                <FormattedMessage id="papers.webinars.video2" />
                                </p>
                            </div>
                            <div className="col-md-6 mb-4 px-2">
                                <iframe className="embed-responsive-item video-frame video-frame-mini" src="https://www.youtube.com/embed/NyKg-v3GcTU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                <p className="text-primary mb-2" style={{ fontSize: "24px", fontWeight: "600" }}>
                                <FormattedMessage id="papers.webinars.video3" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Blog */}
                <div className="container-fluid background-cover" style={{ textAlign: "center", background: "url(" + backgroundBlog + ")", backgroundSize: "cover", padding: "10vh 20pt" }}>
                    <div className="container">
                        <h2 className="text-white" style={{ marginBottom: "40pt" }}>
                            <FormattedMessage id="papers.blogs.title" />
                        </h2>
                        <div className="d-flex justify-content-center align-items-start flex-wrap" style={{ width: "100%", gap: "20pt" }}>
                           
                            { !loading && blogs.map((item: any, index: any) => (
                                            <div className="card-list text-white" key={index}>
                                    <img src={
                                        item.node.mainImage.localFile.publicURL
                                    } className="card-img-top" alt="cover" />
                                    <div className="card-body text-left" style={{ height: "250pt" }}>
                                        <h4 className="card-title card-title-blog text-white mb-4">
                                            {item.node.title}
                                        </h4>
                                        <p className="card-text text-white mb-2">
                                            {item.node.body.childMarkdownRemark.excerpt}
                                        </p>
                                        <a href={"/blog/" + item.node.slug}
                                        style={{ color: "#00FCC1", textAlign: "left" }}>
                                            Ver más
                                        </a>
                                    </div>
                                </div>
                            ))}
                            { loading && [1, 2, 3].map((item, index) => (
                                <div className="card-list text-white" key={index}>
                                    <img src={placeholder} className="card-img-top" alt="cover" />
                                    <div className="card-body text-left">
                                        <h4 className="card-title card-title-blog text-white mb-4">
                                            <span className="placeholder col-12">
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp; &nbsp; &nbsp;
                                            </span>
                                        </h4>
                                        <p className="card-text text-white mb-2">
                                        <span className="placeholder col-12">
                                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        </span>
                                        </p>
                                        <a href="#" style={{ color: "#00FCC1", textAlign: "left" }}>
                                            Ver más
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section>


            <Footer theme="primary" page={'cardsolution'} />
        </>
    );

    return <IndexLayout navbarTheme={"default"} render={content} locale={localeCode} location={location} />
}

export default PaperTemplate;